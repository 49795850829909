import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from '../home/home-routing.module';
import { HomeComponent } from '../home/home.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ArticlesModule } from '../articles/articles.module';
import { GeneralModule } from '../general/general.module';
import { ChapterModule } from '../chapter/chapter.module';


@NgModule({
  declarations: [
    HomeComponent,
    

  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FontAwesomeModule,
    ChapterModule,
    GeneralModule
  ],
 
})
export class HomeModule { }