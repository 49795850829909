<div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">

        <!-- <div *ngIf="cseBjs?.length > 0"
            class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">
            <app-article-card-list *ngFor="let article of cseBjs; let i = index" [article]="article"
                class="article-listing-card"></app-article-card-list>
        </div> -->
    <div *ngFor="let chapter of chapter_list">
        <h4 class="content-title">{{chapter.heading}}</h4>
        <ol class="main-list">
            <li *ngFor="let parts of chapter.parts">{{parts.part}}
                <ol>
                    <li *ngFor="let articles of parts.articles">
                        <a [href]="'/' + chapter.heading_id + '/' + parts.part_id + '/' + articles.article_link">
                            {{ articles.article_title }}
                        </a>                        
                        <span *ngIf="articles.authors.length > 0"> (
                            <ng-container *ngFor="let authors of articles.authors; let i = index">
                                {{authors.given_name}} {{authors.surname}}{{i < articles.authors.length - 1 ? ', ' : ''}}
                            </ng-container>
                        )</span>
                    </li>                    
                </ol>
            </li>
            <!-- <li>Clinical Aspects
                <ol>
                    <li><a href="">Clinical Diagnosis of Leprosy</a>
                        <span>( <a href="">JH Richardus</a>, <a href="">E Ignotti</a>,<a href=""> WCS Smith</a> )</span>
                    </li>
                    <li><a href="">Leprosy Reactions</a>
                        <span>( <a href="">SL Walker</a> )</span>
                    </li>
                    <li><a href="">Differential Diagnosis of Leprosy</a>
                        <span>(<a href="">SL Moschella</a>, <a href="">V Garcia Albea</a> )</span>
                    </li>
                    <li><a href="">Pathogenesis and Pathology of Leprosy</a>
                        <span>(<a href="">DM Scollard</a>)</span>
                    </li>
                    <li><a href="">Neurological Manifestations of Leprosy</a>
                        <span>(<a href="">J Vijayan</a>, <a href="">EP Wilder-Smith</a>)</span>
                    </li>
                    <li><a href="">Treatment of Leprosy</a>
                        <span>(<a href="">DN Lockwood</a>)</span>
                    </li>
                </ol>
            </li> -->
        </ol>
    </div>
</div>