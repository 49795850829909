import { Component, OnInit } from '@angular/core';
import { env } from '../../config/config';
import { ArticleService } from '../../services/article.service';

@Component({
  selector: 'app-chapter-list',
  templateUrl: './chapter-list.component.html',
  styleUrl: './chapter-list.component.scss'
})
export class ChapterListComponent implements OnInit {
  journal_id = '';
  chapter_list:any[]=[];
  constructor(
    private articleService:ArticleService
  ){}

  ngOnInit() {
    this.journal_id = env.journalName;
    this.articleService.getArticleListLepra(this.journal_id).subscribe(result=>{
      this.chapter_list = result.data;
    })

    
  }

  isObjectArray(authors: any[]): boolean {
		return typeof authors[0] === 'object';
	}

}
